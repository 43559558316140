import { useState, useEffect } from "react";

function ScrollTop() {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  return (
    <div>
      <div className={`scrollToTop ${showScrollToTop ? "show" : ""}`}>
        <button onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
          <img src="/./images/upArrow.png" alt="Up Arrow" />
        </button>
      </div>
    </div>
  );
}

export default ScrollTop;
