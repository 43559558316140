import React from "react";

export default function Whatsapp() {
  // User check mobile or pc
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Render the Sticky Whatsapp Component
  return (
    <div className="whatsappContainer">
      <a
        href={
          isMobile
            ? "whatsapp://send?phone=+905326359825"
            : "https://web.whatsapp.com/send?phone=+905326359825"
        }
        target="_blank"
        rel="noopener noreferrer">
        <img src="/./images/whatsappIcon.png" alt="WhatsApp Icon" />
      </a>
    </div>
  );
}
