import React from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import useLocales from "../hooks/useLocales";

export default function Head() {
  const { translation : t } = useLocales()
  return (
    <HelmetProvider>
    <div>
      <Helmet>
        <title>Bayrakçıoğlu İnşaat</title>
        <meta
          name="description"
          content={t("description")}
        />
        <meta
          name="keywords"
          content="construction, architecture, design, interior, exterior, building, inşaat, turkey, turkiye, hatay, "
        />
      </Helmet>
    </div>
  </HelmetProvider>
  )
}
