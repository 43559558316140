import MainNavbar from "../layout/main/MainNavbar";
import AnimatedPage from "../components/AnimatedPage";

function Header() {
  return (
    <AnimatedPage>
      <MainNavbar />
    </AnimatedPage>
  );
}

export default Header;
