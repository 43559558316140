import React from "react";
import IntroContainer from "../components/IntroContainer";
import ContactContent from "../components/ContactContent";
import AnimatedPage from "../components/AnimatedPage";
import { useTranslation } from "react-i18next";
import Location from "../components/Location";

export default function Contact() {
    const { t } = useTranslation();

    return (
        <div>
            <AnimatedPage>
                <IntroContainer introBg="contactBg" introHeading={t("introContact")} />
                <div className="contactContentBg">

                <ContactContent />
                <Location />
                </div>
            </AnimatedPage>
        </div>
    );
}
