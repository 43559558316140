import React from "react";
import useLocales from "../hooks/useLocales";

export default function NotFound() {
  const { translation: t } = useLocales();
  return (
    <div className="notFoundContainer">
      <div className="section">
        <h1 className="error">404</h1>
        <div class="page">{t("notFound")}</div>
        <a className="backHome" href="/">
          {t("notFoundButton")}
        </a>
      </div>
    </div>
  );
}
