import React from "react";
import { Container } from "react-bootstrap";

export default function ContactContent() {
  // User check mobile or pc
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Render the ContactContent component
  return (
    <Container
      fluid
      className="w-75 p-0 d-flex flex-column text-center contactContainer">
      <p>Ekinci Mah. Çevreyolu Cad. No:173 Antakya/HATAY</p>
      <p>
        <a
          href={
            isMobile
              ? "whatsapp://send?phone=+905326359825"
              : "https://web.whatsapp.com/send?phone=+905326359825"
          }>
          <strong>Whatsapp: </strong>
          +90 532 635 98 25
        </a>{" "}
      </p>
      <p>
        <a href="mailto:izzet.byrkcgl@gmail.com">
          <strong>Mail: </strong>
          izzet.byrkcgl@gmail.com
        </a>
      </p>
      <p>
        <a href="https://www.instagram.com/byrkcglinsaat/">
          <strong>Instagram:</strong>
          byrkcglinsaat
        </a>
      </p>
    </Container>
  );
}
