import React from 'react'
import IntroContainer from "../components/IntroContainer";
import AboutContent from "../components/AboutContent";
import { useTranslation } from "react-i18next";
import AnimatedPage from '../components/AnimatedPage';

export default function About() {
  const { t } = useTranslation();

  return (
    <>
    <AnimatedPage>
      <IntroContainer introBg="aboutUsBg" introHeading={t("introAboutUs")} />
      <AboutContent />
    </AnimatedPage>
    </>
  )
}
