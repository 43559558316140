import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Whatsapp from "./Whatsapp";
import ScrollTop from "./ScrollTop";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";

function WhiteFooter() {
  const { t } = useTranslation();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <Container fluid className="w-75 p-0">
      <Row className="mt-5 mb-5 pt-5 pb-5">
        <Col lg={4} md={4} sm={12} className="align-items-center">
          <Logo />
        </Col>
        <Col
          lg={4}
          md={4}
          sm={12}
          className="d-flex flex-column mt-sm-4 xsResponsive">
          <h5 className="infoHeading">{t("addressTitle")}</h5>
          <p className="infoContent">Ekinci Mah. Çevreyolu Cad. No:173 Antakya/HATAY</p>
        </Col>
        <Col
          lg={4}
          md={4}
          sm={12}
          className="d-flex flex-column mt-sm-4 xsResponsive">
          <h5 className="infoHeading">{t("contactInfo")}</h5>
          <p className="infoContent">
            <a
              href={
                isMobile
                  ? "whatsapp://send?phone=+905326359825"
                  : "https://web.whatsapp.com/send?phone=+905326359825"
              }>
              <img
                src="/./images/whatsapp.png"
                alt="whatsapp logo"
                width={18}
                height={18}
              />
              +90 532 635 98 25
            </a>
          </p>
          <p className="infoContent">
            <a href="mailto:izzet.byrkcgl@gmail.com">
              <img
                src="/./images/mail.png"
                alt="mail logo"
                width={18}
                height={18}
              />
              izzet.byrkcgl@gmail.com
            </a>
          </p>
          <p className="infoContent">
            <a href="https://www.instagram.com/byrkcglinsaat/">
              <img
                src="/./images/instagram.png"
                alt="whatsapp logo"
                width={18}
                height={18}
              />
              byrkcglinsaat
            </a>
          </p>
        </Col>
      </Row>
      <Whatsapp />
      <ScrollTop />
    </Container>
  );
}

export default WhiteFooter;
