import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Paths from "./routes/paths";
import Head from "./components/Head";

export default function App() {

  return (
    <>
      <Head />
      <div className="App">
        <Paths />
      </div>
    </>
  );
}
