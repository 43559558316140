import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function ProjectNavigation(props) {
  const { prevTitle, nextTitle, nextId, prevId, currentId } = props;
  const navigate = useNavigate();

  const handleNav = (id) => {
    navigate(`/projects/${id}`);
  };

  const prevDisp = Number(currentId) === 1 ? "d-none" : "w-25";
  const nextDisp = Number(currentId) === 18 ? "d-none" : "w-25";
  const currentClass =
    "d-flex justify-content-between align-items-center text-center";

  return (
    <div
      className={
        1 < Number(currentId) && 18 > Number(currentId)
          ? currentClass
          : "d-flex justify-content-center text-center"
      }>
      <div className={prevDisp}>
        <BsChevronLeft
          className="text-secondary btnPrevNext"
          size={30}
          onClick={() => handleNav(prevId)}
        />

        <p className="projectNavP">{prevTitle}</p>
      </div>
      <div className={nextDisp}>
        <BsChevronRight
          className="text-secondary btnPrevNext"
          size={30}
          onClick={() => handleNav(nextId)}
        />
        <p className="projectNavP">{nextTitle}</p>
      </div>
    </div>
  );
}

export default ProjectNavigation;
