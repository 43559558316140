import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

export default function Logo() {
  return (
    <Link href="/">
      <Box
        component="img"
        src="static/logo/logo.png"
        sx={{
          width:  100,
          height: 120,
        }}
      />
    </Link>
  );
}
