export default function Location() {
  return (
    <div style={{height:'450px', marginTop:'20px'}}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3217.6489596821766!2d36.156785475813145!3d36.24802057240974!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1525e9e128e4d8a3%3A0x9ec7285371b02a64!2sEkinci%2C%20%C3%87evre%20Yolu%20No%3A173%2C%2031180%20Antakya%2FHatay!5e0!3m2!1str!2str!4v1695285586529!5m2!1str!2str"
        width="600"
        height="450"
        style={{border:0, width:"100%", height:"100%"}}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="location"
      ></iframe>
    </div>
  );
}
