import React from "react";

// A component that contain hero image and title of pages
export default function IntroContainer({ introBg, introHeading }) {
  return (
    <div className={introBg}>
      <h1 className="introHeading">{introHeading}</h1>
    </div>
  );
}
