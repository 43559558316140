import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";


export default function useLocales() {
  const { i18n, t: translation } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");

  const LANGS = [
    {
      label: translation("lang1"),
      value: "en",
      image: "../static/icons/united-kingdom.png",
    },
    {
      label: translation("lang2"),
      value: "tr",
      image: "../static/icons/turkey.png",
    },
  ];

  const currentLang = LANGS.find((item) => item.value === lang);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    changeLanguage(lang);
  }, []);

  const changeLanguage = (lang) => {
    setLang(lang);
    localStorage.setItem("lang", lang);
    i18n.changeLanguage(lang);
  };

  return {
    translation,
    currentLang,
    changeLanguage,
    LANGS,
  };
}
