import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ProjectsItem() {
  const { t } = useTranslation();

  const srcPath = "./images/project-item/";

  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/projects/${id}`);
  };

  const projectsMap = {
    project1: {
      number: "1",
      src: srcPath + "qatarDoha.jpeg",
      title: t("sliderCaption1"),
    },
    project2: {
      number: "2",
      src: srcPath + "bilginVillaProject1.jpeg",
      title: t("sliderCaption2"),
    },
    project3: {
      number: "3",
      src: srcPath + "villaLondon1.jpeg",
      title: t("sliderCaption5"),
    },
    project4: {
      number: "4",
      src: srcPath + "sudan1.jpeg",
      title: t("sliderCaption4"),
    },
    project5: {
      number: "5",
      src: srcPath + "cafeInterior1.jpeg",
      title: t("sliderCaption6"),
    },
    project6: {
      number: "6",
      src: srcPath + "antakyaIndustrialSite.jpeg",
      title: t("sliderCaption3"),
    },
    project7: {
      number: "7",
      src: srcPath + "technoVillaProject1.jpeg",
      title: t("sliderCaption7"),
    },
    project8: {
      number: "8",
      src: srcPath + "harbiyeVilla1.jpeg",
      title: t("sliderCaption8"),
    },
    project9: {
      number: "9",
      src: srcPath + "futuristicCapsuleModule.jpeg",
      title: t("sliderCaption9"),
    },
    project10: {
      number: "10",
      src: srcPath + "modernCommunityModule.jpeg",
      title: t("sliderCaption10"),
    },
    project11: {
      number: "11",
      src: srcPath + "sideProjectInAntalya.jpeg",
      title: t("sliderCaption11"),
    },
    project12: {
      number: "12",
      src: srcPath + "qatarDohaBusinessCenter1.jpeg",
      title: t("sliderCaption12"),
    },
    project13: {
      number: "13",
      src: srcPath + "ergunBasGroup.jpeg",
      title: t("sliderCaption13"),
    },
    project14: {
      number: "14",
      src: srcPath + "antakyaPrivateHousing.jpeg",
      title: t("sliderCaption14"),
    },
    project15: {
      number: "15",
      src: srcPath + "antakyaOdabasi.jpeg",
      title: t("sliderCaption15"),
    },
    project16: {
      number: "16",
      src: srcPath + "cekmeceNeighbourhood.jpeg",
      title: t("sliderCaption16"),
    },
    project17: {
      number: "17",
      src: srcPath + "tatliApartment1.jpeg",
      title: t("sliderCaption17"),
    },
    project18: {
      number: "18",
      src: srcPath + "technoBathroom.jpeg",
      title: t("sliderCaption18"),
    },
  };

  Object.values(projectsMap).map((item) => {
    return (item.title = item.title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" "));
  });

  return (
    <div className="projectItemsContainerBg">
      <Container fluid className="w-75 p-0">
        <Row>
          {Object.values(projectsMap).map((item, index) => {
            return (
              <Col
                key={index}
                lg={4}
                md={6}
                sm={12}
                className="d-flex justify-content-center mt-5 mt-sm-5 mt-xs-5">
                <Card
                  className="bg-transparent border-0 cardContainer"
                  style={{ width: "18.75rem" }}
                  onClick={() => handleClick(item.number)}>
                  <Card.Img
                    variant="top"
                    src={item.src}
                    style={{ height: "15rem" }}
                  />
                  <Card.Body className="cardBody">
                    <Card.Title className="cardTitle">{item.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}
