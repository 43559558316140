import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Features() {
  const { t } = useTranslation();

  const featuresMap = {
    features1: {
      src: "./images/features1.png",
      alt: "Interior/Exterior Design",
      content: t("features1"),
    },
    features2: {
      src: "./images/features2.png",
      alt: "3D Visualization",
      content: t("features2"),
    },
    features3: {
      src: "./images/features3.png",
      alt: "Implementation",
      content: t("features3"),
    },
    features4: {
      src: "./images/features4.png",
      alt: "3D Animation",
      content: t("features4"),
    },
  };

  return (
    <Container fluid className="w-75 p-0 featuresContainer">
      <Row>
        {Object.values(featuresMap).map((item, index) => {
          return (
            <Col key={index} className="mt-5" lg={3} md={6} sm={12}>
              <img
                className="featuresImages"
                src={item.src}
                alt={item.alt}
                width={96}
                height={96}></img>
              <p className="featuresContent">{item.content}</p>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Features;
