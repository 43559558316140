import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function Services() {
  const { t } = useTranslation();

  const cardItemsMap = {
    card1: {
      number: "01",
      src: "./images/card-item/card1.jpeg",
      title: t("servicesTitle1"),
    },
    card2: {
      number: "02",
      src: "./images/card-item/card2.jpeg",
      title: t("servicesTitle2"),
    },
    card3: {
      number: "03",
      src: "./images/card-item/card3.jpeg",
      title: t("servicesTitle3"),
    },
    card4: {
      number: "04",
      src: "./images/card-item/card4.jpeg",
      title: t("servicesTitle4"),
    },
    card5: {
      number: "05",
      src: "./images/card-item/card5.jpeg",
      title: t("servicesTitle5"),
    },
    card6: {
      number: "06",
      src: "./images/card-item/card6.jpeg",
      title: t("servicesTitle6"),
    },
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const cardClass = screenWidth < 991.5 ? "18.75rem" : "22.5rem";

  return (
    <div className="servicesBg">
      <Container className="w-75 p-0">
        <Row>
          <Col lg={4} md={12} sm={12} className="text-center">
            <div className="servicesHeading">
              <h3>{t("servicesHeading")}</h3>
            </div>
          </Col>
        </Row>
        <Row>
          {Object.values(cardItemsMap).map((item, index) => {
            return (
              <Col
                key={index}
                lg={6}
                md={12}
                sm={12}
                className="d-flex justify-content-center mt-5 mt-sm-5 mt-xs-5">
                <Link className="servicesLink" to="/projects">
                  <Card
                    className="bg-transparent border-0 cardContainer"
                    style={{ width: cardClass }}>
                    <h5 className="cardNumber">{item.number}</h5>

                    <Card.Img
                      variant="top"
                      src={item.src}
                      style={{
                        width: cardClass,
                        height: "15rem",
                      }}
                    />
                    <Card.Body className="cardBody">
                      <Card.Title className="cardTitle">
                        {item.title}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default Services;
