import useLocales from "../../hooks/useLocales";

function MenuConfig() {
    const { translation: t } = useLocales();

    const menuConfig = [
        {
            title: t("navItem1"),
            path: '/',
        },
        {
            title: t("navItem2"),
            path: '/about',
        },
        {
            title: t("navItem3"),
            path: '/projects',
        },
        {
            title: t("navItem4"),
            path: '/contact',
        }
    ];

    return menuConfig;
}

export default MenuConfig;
