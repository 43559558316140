import React from 'react'
import WhiteFooter from './WhiteFooter'
import ColouredFooter from './ColouredFooter'

export default function Footer() {
    return (
        <>
            <WhiteFooter />
            <ColouredFooter />
        </>
    )
}
