import React from 'react'
import AnimatedPage from '../components/AnimatedPage'
import IntroContainer from "../components/IntroContainer";
import ProjectsItem from "../components/ProjectsItem";
import { useTranslation } from "react-i18next";

export default function Projects() {
    const { t } = useTranslation();

    return (
        <>
            <AnimatedPage>
                <IntroContainer
                    introBg="projectsBg"
                    introHeading={t("introProjects")}
                />
                <ProjectsItem />
            </AnimatedPage>
        </>
    )
}
