import { Container } from "react-bootstrap";

// Define a component for the colored footer section
function ColouredFooter() {
  return (
    <Container fluid className="w-100 p-0 text-center">
      <div className="colouredContainer">
        <p>
          Copyright © 2023 |<strong> Bayrakçıoğlu İnşaat</strong> | All
          Rights Reserved
        </p>
      </div>
    </Container>
  );
}

export default ColouredFooter;
