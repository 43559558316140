import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import MenuConfig from "./MenuConfig";
import LanguageOptions from "../../components/LanguageOptions";
import Logo from "../../components/Logo";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import useLocales from "../../hooks/useLocales";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

function DrawerAppBar(props) {
  const location = useLocation();
  const activePath = location.pathname;
  const { LANGS, changeLanguage, currentLang } = useLocales();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openLang, setOpenLang] = React.useState(false);
  const menuConfig = MenuConfig();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleLangClick = () => {
    setOpenLang(!openLang);
  };

  const handleLangOption = (lang) => {
    changeLanguage(lang);
    setOpenLang(false);
  };

  const drawer = (
    <Box sx={{ textAlign: "center", pl: 4, pr: 4 }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <Logo />
      </Typography>
      <Divider />
      <List>
        {menuConfig.map((item, index) => (
          <ListItem key={index} disablePadding onClick={handleDrawerToggle}>
            <ListItemButton
              href={item.path}
              sx={{
                backgroundColor: activePath === item.path ? "#F8F0E5" : "",
                color: activePath === item.path ? "#E5B565" : "inherit",
              }}
            >
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItemButton onClick={handleLangClick}>
          <ListItemText primary={currentLang.label} />
          {openLang ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openLang} unmountOnExit>
          <List
            component="div"
            disablePadding
            onClick={handleDrawerToggle}
            sx={{ pl: 2 }}
          >
            {LANGS.map((lang) => (
              <ListItemButton
                key={lang.value}
                onClick={() => handleLangOption(lang.value)}
              >
                <ListItemText primary={lang.label} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="sticky"
        sx={{ backgroundColor: "#fff" }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { md: "block" } }}
          >
            <Logo />
          </Typography>
          <IconButton
            color="#000"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

            {menuConfig.map((item, index) => (
              <Link
                key={index}
                href={item.path}
                color="#000"
                sx={{
                  textTransform: "uppercase",
                  textDecoration: "none",
                  color: activePath === item.path ? "#E5B565" : "#000",
                  margin: {sm: '0 5px', md: '0 20px'},
                  "&:hover": { color: "#E5B565", backgroundColor: "none" },
                }}
              >
                {item.title}
              </Link>
            ))}
            <LanguageOptions />
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          className="drawer"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{ display: { sm: 'block', md: 'none'  },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default DrawerAppBar;
