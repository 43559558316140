import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import IntroContainer from "../components/IntroContainer";
import AnimatedPage from "../components/AnimatedPage";
import ProjectNavigation from "../components/ProjectNavigation";
import { useTranslation } from "react-i18next";


export default function ProjectDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const projectKey = params.id;
  const srcPath = "/./images/project-item/";

  const projectDetails = {
    1: {
      src: {
        img1: srcPath + "qatarDoha.jpeg",
      },
      title: t("sliderCaption1"),
      description: t("projectDetails1"),
    },
    2: {
      src: {
        img1: srcPath + "bilginVillaProject1.jpeg",
        img2: srcPath + "bilginVillaProject2.jpeg",
      },
      title: t("sliderCaption2"),
      description: t("projectDetails2"),
    },
    3: {
      src: {
        img1: srcPath + "villaLondon1.jpeg",
        img2: srcPath + "villaLondon2.jpeg",
        img3: srcPath + "villaLondon3.jpeg",
      },
      title: t("sliderCaption5"),
      description: t("projectDetails3"),
    },
    4: {
      src: {
        img1: srcPath + "sudan1.jpeg",
        img2: srcPath + "sudan2.jpeg",
      },
      title: t("sliderCaption4"),
      description: t("projectDetails4"),
    },
    5: {
      src: {
        img1: srcPath + "cafeInterior1.jpeg",
        img2: srcPath + "cafeInterior2.jpeg",
        img3: srcPath + "cafeInterior3.jpeg",
        img4: srcPath + "cafeInterior4.jpeg",
      },
      title: t("sliderCaption6"),
      description: t("projectDetails5"),
    },
    6: {
      src: {
        img1: srcPath + "antakyaIndustrialSite.jpeg",
      },
      title: "Antakya Industrial Site Workplace",
      description: t("projectDetails6"),
    },
    7: {
      src: {
        img1: srcPath + "technoVillaProject1.jpeg",
        img2: srcPath + "technoVillaProject2.jpeg",
      },
      title: t("sliderCaption7"),
      description: t("projectDetails7"),
    },
    8: {
      src: {
        img1: srcPath + "harbiyeVilla1.jpeg",
        img2: srcPath + "harbiyeVilla2.jpeg",
      },
      title: t("sliderCaption8"),
      description: t("projectDetails8"),
    },
    9: {
      src: {
        img1: srcPath + "futuristicCapsuleModule.jpeg",
      },
      title: t("sliderCaption9"),
      description: t("projectDetails9"),
    },
    10: {
      src: {
        img1: srcPath + "modernCommunityModule.jpeg",
      },
      title: t("sliderCaption10"),
      description: t("projectDetails10"),
    },
    11: {
      src: {
        img1: srcPath + "sideProjectInAntalya.jpeg",
      },
      title: t("sliderCaption11"),
      description: t("projectDetails11"),
    },
    12: {
      src: {
        img1: srcPath + "qatarDohaBusinessCenter1.jpeg",
        img2: srcPath + "qatarDohaBusinessCenter2.jpeg",
      },
      title: t("sliderCaption12"),
      description: t("projectDetails12"),
    },
    13: {
      src: {
        img1: srcPath + "ergunBasGroup.jpeg",
      },
      title: t("sliderCaption13"),
      description: t("projectDetails13"),
    },
    14: {
      src: {
        img1: srcPath + "antakyaPrivateHousing.jpeg",
      },
      title: t("sliderCaption14"),
      description: t("projectDetails14"),
    },
    15: {
      src: {
        img1: srcPath + "antakyaOdabasi.jpeg",
      },
      title: t("sliderCaption15"),
      description: t("projectDetails15"),
    },
    16: {
      src: {
        img1: srcPath + "cekmeceNeighbourhood.jpeg",
      },
      title: t("sliderCaption16"),
      description: t("projectDetails16"),
    },
    17: {
      src: {
        img1: srcPath + "tatliApartment1.jpeg",
        img2: srcPath + "tatliApartment2.jpeg",
      },
      title: t("sliderCaption17"),
      description: t("projectDetails17"),
    },
    18: {
      src: {
        img1: srcPath + "technoBathroom.jpeg",
        img2: srcPath + "technoFloor.jpeg",
        img3: srcPath + "technoGround.jpeg",
      },
      title: t("sliderCaption18"),
      description: t("projectDetails18"),
    },

    19: {},
  };

  const { src, title, description } = projectDetails[projectKey];

  return (
    <>
      <AnimatedPage>
        <IntroContainer
          introBg="projectsBg"
          introHeading={t("introProjects")}
        />
        <Container fluid className="w-75 p-0">
          <Row className="mt-5 mb-5 pt-5 pb-5">
            <Col md={8}>
              {Object.values(src).map((image) => {
                return <Image className="pb-5" src={image} fluid></Image>;
              })}
            </Col>
            <Col md={4}>
              <div className="sticky">
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            </Col>
          </Row>

          <ProjectNavigation
            currentId={projectKey}
            prevTitle={
              Number(projectKey) - 1 > 0
                ? projectDetails[Number(projectKey) - 1].title
                : ""
            }
            nextTitle={projectDetails[1 + Number(projectKey)].title}
            nextId={Number(projectKey) + 1}
            prevId={Number(projectKey) - 1}
          />
        </Container>

      </AnimatedPage>
    </>
  );
}
