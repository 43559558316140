import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export default function About() {
  // i18n translation function
  const { t } = useTranslation();
  // state to track screen width for responsive design
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Event listener to handle window resizing
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    // Add event listener on component mount
    window.addEventListener("resize", handleResize);
    // Remove event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine container class based on screen width
  const containerClass =
    screenWidth < 991.5
      ? "aboutUsContainer w-100 bg-transparent"
      : "aboutUsContainer w-75 bg-transparent";

  // Render the About component
  return (
    <div className="aboutUsContainerBg">
      <Container fluid className={containerClass}>
        <Row className="aboutUsRow">
          {/* Render the About Us content */}
          <Col xl={6} lg={12} md={12} sm={12} className="aboutUsContent p-0">
            <p>
              {t("aboutUsContent1")}
              <br />
              <br />
              {t("aboutUsContent2")}
            </p>
          </Col>
          {/* Render the About Us image */}
          <Col xl={6} lg={12} md={12} sm={12} className="aboutUsImage p-0">
            <img
              className="p-0"
              alt="About Us"
              src="./images/aboutUsImage.png"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
