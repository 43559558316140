import React from 'react'
import HomeCarousel from '../components/HomeCarousel'
import Features from '../components/Features'
import Services from '../components/Services'
import AnimatedPage from '../components/AnimatedPage'

export default function Home() {
  return (
    <>
      <AnimatedPage>
        <HomeCarousel />
        <Features />
        <Services />
      </AnimatedPage>
    </>
  )
}
